import React from "react";
import moment from "moment";
import { Select, Input} from 'antd';
import * as S from "./styles";
import { SubDetails } from "@components/containers";
import { formatStoreOperatingDays } from "src/core/checkoutScheduleUtils";
const { Option } = Select;

interface IProps {
    startDateTime: any;
    operatingDays: string;
    onChange: (e: any) => void;
    value: string;
}

export const DATE_FORMAT = "YYYY-MM-DD";
const DATE_DISPLAY_FORMAT = "MMM DD (ddd)";

export const initOptions = (startDateTime: any, operatingDays: string) => {
    const availableDayWeekStr = formatStoreOperatingDays(operatingDays).toLowerCase();
    let daysAdded = 14;
    const options = [{
        display: moment(startDateTime).format(DATE_DISPLAY_FORMAT),
        value: moment(startDateTime).format(DATE_FORMAT)
    }];
    let i = 1;
    while (i < daysAdded) {
        const date = moment(startDateTime).add(i, 'days');
        const day = date.format('ddd').toLowerCase()
        if (availableDayWeekStr.indexOf(day) !== -1) {
            options.push({
                display: date.format(DATE_DISPLAY_FORMAT),
                value: date.format(DATE_FORMAT)
            })
        } else {
            daysAdded++;
        }
        i++;   
    }

    return options;
}

const DateOptions: React.FC<IProps> = ({
    startDateTime,
    operatingDays,
    onChange,
    value,
}: IProps) => {
    const defaultValue = moment(startDateTime).format(DATE_FORMAT);
    const options = initOptions(startDateTime, operatingDays);
    
    return (
        <Input.Group style={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center', height: 40, width: '100%' ,marginBottom: '0.5rem', marginTop: '1rem'}} compact>
            <S.IconContainer>
            <SubDetails
            items={[
                {
                iconName: 'far fa-calendar',
                iconSize: 20,
                paddingLeft: 2.5,
                text: ' '
                },
            ]}
            />
            </S.IconContainer>
            <Select 
                defaultValue={defaultValue}
                size={'large'} 
                showArrow 
                allowClear 
                placeholder={'Select your delivery date'}
                onChange={onChange}
                value={value || defaultValue}
            >
                {
                    options.map(option => (
                        <Option value={option.value}>{option.display}</Option>
                    ))
                }
            </Select>
        </Input.Group>
    );
};

export default DateOptions;