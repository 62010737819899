import React from "react";
import "../globalStyles/scss/index.scss";
import Layout from '../layout';
import Routes from '../routes';
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss';

const App = () => {
  return (
    <Layout>
      <Routes />
    </Layout>
  )
};

export default App;
