import {Base64} from 'js-base64';
import {UserLocationProps} from 'src/layout/Context';

const CHECKOUT_STORE_KEY = 'data_checkout';
const MENUS_STORE_KEY = 'data_menus';
const USER_LOCATION_KEY = 'data_user_location';
const AUTOCOMPLETE_LOCATION_SELECTION_CACHE = 'location_select_cache';
const CART_ITEM_NOTES = 'data_item_cart_notes';
const CHECKOUT_NOTES = 'data_checkout_notes';
const HAS_ADULT_CONTENT = 'data_has_adult_content';
const CHECKOUT_SCHEDULES = 'data_checkout_schedules';
import productPlaceholderDefault from 'src/images/merchant-placeholder-avatar.jpg';
import productPlaceholderEssentials from 'src/images/product_placeholders/essentials.jpg';
import productPlaceholderFood from 'src/images/product_placeholders/food.jpg';
import productPlaceholderGroceries from 'src/images/product_placeholders/groceries.jpg';
import productPlaceholderLifestyle from 'src/images/product_placeholders/lifestyle.jpg';
import productPlaceholderDrinks from 'src/images/product_placeholders/drinks.jpg';

export const isNumber = (value) => {
	return !isNaN(Number(value));
};

export const getCheckoutToLocalStorage = () => {
	try {
		const data = localStorage.getItem(CHECKOUT_STORE_KEY);
		if (data) {
			const checkout = JSON.parse(data);
			if (checkout.token) {
				return checkout;
			}
		}
	} catch (e) {
		return null;
	}
	return null;
};

export const setCheckoutToLocalStorage = (data) => {
	if (!data) {
		return null;
	}

	localStorage.setItem(CHECKOUT_STORE_KEY, JSON.stringify(data));
};

export const removeCheckoutToLocalStorage = () => {
	localStorage.removeItem(CHECKOUT_STORE_KEY);
};

export const catchInActiveCheckoutError = (errors) => {
	const inActiveError = errors.find
		? errors.find(
				(error: any) =>
					error.code === 'IN_ACTIVE' && error.field === 'checkoutId',
		  )
		: null;
	if (inActiveError) {
		localStorage.removeItem(CHECKOUT_STORE_KEY);
		window.location.href = '/';
	}
};

export const validateEmail = (email) => {
	if (
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
			email,
		)
	) {
		return true;
	}
	return false;
};

export const convertMinutesToHoursDaysString = (mins) => {
	if (mins === 0 || !mins) {
		return `0 min`;
	}

	const days = Math.floor(mins / 1440); // 60*24
	const hours = Math.floor((mins - days * 1440) / 60);
	const minutes = Math.round(mins % 60);

	const seperator = ', ';
	let value = '';
	let numVal = 0;

	numVal = days;
	if (numVal > 0) {
		value += `${numVal} day${numVal > 1 ? 's' : ''}${seperator}`;
	}
	numVal = hours;
	if (numVal > 0) {
		value += `${numVal} hr${numVal > 1 ? 's' : ''}${seperator}`;
	}
	numVal = minutes;
	if (numVal > 0) {
		value += `${numVal} min${numVal > 1 ? 's' : ''}${seperator}`;
	}
	if (value.length > 0) {
		value = value.substring(0, value.length - seperator.length);
	}
	return value;
};

export const capitalizeFirstLetter = (text: string) => {
	if (!text) {
		return null;
	}

	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const setMenuItems = (items: any) => {
	if (!items) {
		return null;
	}

	localStorage.setItem(MENUS_STORE_KEY, JSON.stringify(items));
};

export const getMenuItems = () => {
	try {
		const items = localStorage.getItem(MENUS_STORE_KEY);
		if (items) {
			return JSON.parse(items);
		}
	} catch (e) {
		return null;
	}
	return null;
};

export const removeUserLocation = () => {
	localStorage.removeItem(USER_LOCATION_KEY);
	return true;
};

export const setUserLocation = (location: UserLocationProps) => {
	if ((!location?.latitude || !location?.longitude) && !location?.placeId) {
		return null;
	}

	localStorage.setItem(USER_LOCATION_KEY, JSON.stringify(location));
	return true;
};

export const getUserLocation = (): UserLocationProps => {
	try {
		const data = localStorage.getItem(USER_LOCATION_KEY);
		if (data) {
			return JSON.parse(data);
		}
	} catch (e) {
		return null;
	}
	return null;
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
	if (!lat1 || !lon1 || !lat2 || !lon2) {
		return 0; // We make sure all coordinates are valid
	}

	const deg2rad = (deg) => {
		return deg * (Math.PI / 180);
	};

	const R = 6371; // Radius of the earth in km
	const dLat = deg2rad(lat2 - lat1); // deg2rad below
	const dLon = deg2rad(lon2 - lon1);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) *
			Math.cos(deg2rad(lat2)) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c; // Distance in km
	return Number(d.toFixed(1));
};

export const setLocationOptionCache = (options: any[]) => {
	if (!options) {
		return null;
	}

	localStorage.setItem(
		AUTOCOMPLETE_LOCATION_SELECTION_CACHE,
		JSON.stringify(options),
	);
	return true;
};

export const getLocationOptionCache = (): any[] => {
	try {
		const options = localStorage.getItem(AUTOCOMPLETE_LOCATION_SELECTION_CACHE);
		if (options) {
			return JSON.parse(options);
		}
	} catch (e) {
		return null;
	}
	return null;
};

export const numberWithCommas = (value, decimal = 2) => {
	if (isNumber(value)) {
		return Number(value)
			.toFixed(decimal)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	} else {
		return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
};

export const convertGraphIDSchema = (graphqlId: string, newSchema: string) => {
	const rawId = Base64.decode(graphqlId);
	const regexp = /(\w+):(\d+)/;
	const [, , id] = regexp.exec(rawId)!;
	if (!id) {
		throw new Error('Id not found');
	}

	return Base64.encode(`${newSchema}:${id}`);
};

export const addCartItemNotesCache = (variantId, merchantId, name, notes) => {
	const data = localStorage.getItem(CART_ITEM_NOTES);
	let itemNotes = [];
	let isExist = false;

	if (data) {
		itemNotes = JSON.parse(data).map((item) => {
			if (!isExist && item?.variantId === variantId) {
				isExist = true;
				item.notes = notes;
			}
			return item;
		});
		if (!isExist) {
			itemNotes.push({variantId, merchantId, name, notes});
		}
	} else {
		itemNotes = [{variantId, merchantId, name, notes}];
	}

	localStorage.setItem(CART_ITEM_NOTES, JSON.stringify(itemNotes));
};

export const removeCartItemNotesCache = (variantId) => {
	const data = localStorage.getItem(CART_ITEM_NOTES);
	let itemNotes = [];
	if (data) {
		itemNotes = JSON.parse(data);
		itemNotes = (itemNotes || []).filter(
			(node) => node?.variantId !== variantId,
		);
	} else {
		itemNotes = [];
	}

	localStorage.setItem(CART_ITEM_NOTES, JSON.stringify(itemNotes));
};

export const getCartItemNotesCache = () => {
	try {
		const data = localStorage.getItem(CART_ITEM_NOTES);
		if (data) {
			return JSON.parse(data);
		}
	} catch (e) {
		return [];
	}
	return [];
};

export const setCartItemNotesCache = (data: any[]) => {
	if (!data) {
		return false;
	}

	localStorage.setItem(CART_ITEM_NOTES, JSON.stringify(data));
	return true;
};

export const setCheckoutNotes = (data: any[]) => {
	if (!data) {
		return false;
	}

	localStorage.setItem(CHECKOUT_NOTES, JSON.stringify(data));
	return true;
};

export const setHasAdultContent = (data: boolean) => {
	if (!data) {
		return false;
	}
	const now = new Date();

	const item = {
		data,
		expiry: now.getTime() + 28800000,
	};

	localStorage.setItem(HAS_ADULT_CONTENT, JSON.stringify(item));
	return true;
};

export const getHasAdultContent = () => {
	try {
		const data = localStorage.getItem(HAS_ADULT_CONTENT);
		if (!data) {
			return false;
		}
		const item = JSON.parse(data);
		const now = new Date();

		if (now.getTime() > item.expiry) {
			localStorage.removeItem(HAS_ADULT_CONTENT);
			return false;
		} else {
			return item?.data;
		}
	} catch (e) {
		return false;
	}
};
export const getCheckoutSchedulesCache = () => {
	try {
		const data = localStorage.getItem(CHECKOUT_SCHEDULES);
		if (data) {
			return JSON.parse(data);
		}
	} catch (e) {
		return [];
	}
	return [];
};

export const setCheckoutSchedulesCache = (data: any[]) => {
	if (!data) {
		return false;
	}

	localStorage.setItem(CHECKOUT_SCHEDULES, JSON.stringify(data));
	return true;
};

export const getCheckoutNotes = () => {
	try {
		const data = localStorage.getItem(CHECKOUT_NOTES);
		if (data) {
			return JSON.parse(data);
		}
	} catch (e) {
		return [];
	}
	return [];
};

export const getProductPlaceholder = (category: string) => {
	category = typeof category === 'string' ? category : '';

	if (
		category.toLocaleLowerCase().trim().indexOf('foods') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('cakes') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('dessert') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('bread') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('biscuits') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('treats') >= 0
	) {
		return productPlaceholderFood;
	} else if (
		category.toLocaleLowerCase().trim().indexOf('beverages') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('juice') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('alcohol') >= 0
	) {
		return productPlaceholderDrinks;
	} else if (
		category.toLocaleLowerCase().trim().indexOf('accessories') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('cosmetics') >= 0
	) {
		return productPlaceholderEssentials;
	} else if (
		category.toLocaleLowerCase().trim().indexOf('groceries') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('frozen') >= 0 ||
		category.toLocaleLowerCase().trim() === 'others'
	) {
		return productPlaceholderGroceries;
	} else if (
		category.toLocaleLowerCase().trim().indexOf('pet') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('flowers') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('shirts') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('footwear') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('hoodies') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('apparel') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('vase') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('bouquet') >= 0 ||
		category.toLocaleLowerCase().trim().indexOf('joyRide originals') >= 0
	) {
		return productPlaceholderLifestyle;
	}
	return productPlaceholderDefault;
};

export const getBrowserType = () => {
	if (
		(navigator.userAgent.indexOf('Opera') ||
			navigator.userAgent.indexOf('OPR')) !== -1
	) {
		return 'Opera';
	} else if (navigator.userAgent.indexOf('Chrome') !== -1) {
		return 'Chrome';
	} else if (navigator.userAgent.indexOf('Safari') !== -1) {
		return 'Safari';
	} else if (navigator.userAgent.indexOf('Firefox') !== -1) {
		return 'Firefox';
	} else if (navigator.userAgent.indexOf('MSIE') !== -1) {
		return 'IE';
	} else {
		return null;
	}
};

export const sorter = (data?: any[]) => {
	if (data) {
		return data.sort((a: any, b: any) => {
			if (a.sortOrder < b.sortOrder) {
				return -1;
			}
			if (a.sortOrder > b.sortOrder) {
				return 1;
			}
			return 0;
		});
	} else {
		return [];
	}
};
