import moment from 'moment';
import {IItems} from '@saleor/sdk/lib/api/Cart/types';
import {mapItemsByMerchant, ImapItemsByMerchant, ImapMerchant} from './utils';
import {convertMinutesToHoursDaysString} from 'src/helpers';

const _MAX_DAYS_DIFF = 15;

export interface CheckoutShippingScheduleProps {
	maxLeadTime: number;
	merchant: ImapMerchant;
	closingHours: Date;
	minSchedule: Date;
	pickupDate?: Date | null;
	errorMessage?: string | null;
	currentDate?: Date | null;
}

export const DAYS_LIST_ENUMS = {
	SINGLE_CHAR: 0,
	STANDARD: 2,
	TRIPPLE_CHAR: 1,
};

export const DAYS_LIST_FORMAT = [
	['Su', 'M', 'T', 'W', 'Th', 'F', 'Sa'],
	['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	[
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	],
];

// Generate default shipping schedule based on store and product settings
export const generateDefaultShippingSchedule = (
	items: IItems,
	currentCheckoutDateString: string | null,
) => {
	const currentMomentDate = () => {
		return currentCheckoutDateString
			? moment(currentCheckoutDateString)
			: moment();
	};

	const productMerchants: ImapItemsByMerchant[] = mapItemsByMerchant(
		items,
		null,
	);
	const checkoutSchedules: CheckoutShippingScheduleProps[] = productMerchants.map(
		({merchant, products}) => {
			const operatingDays =
				merchant && merchant.operatingDays
					? merchant.operatingDays.split(':')
					: [];
			const sortedLeadTime = products.sort((a, b) => {
				return b.leadTime - a.leadTime;
			});
			const maxLeadTime =
				sortedLeadTime && sortedLeadTime.length && sortedLeadTime.length > 0
					? sortedLeadTime[0].leadTime
					: null;

			// Initialize opening & closing hours based on store settings
			let closingHours = null;
			let openningHours = null;
			if (merchant && merchant.storeHourEnd && merchant.storeHourStart) {
				const [hrs, mins, secs] = merchant.storeHourEnd.split(':');
				closingHours = currentMomentDate().set({
					hours: Number(hrs),
					minutes: Number(mins),
					seconds: Number(secs),
				});

				const [openHrs, openMins, openSecs] = merchant?.storeHourStart.split(
					':',
				);
				openningHours = currentMomentDate().set({
					hours: Number(openHrs),
					minutes: Number(openMins),
					seconds: Number(openSecs),
				});
			}

			// Initialize minimum schedule that consumer can use
			let minSchedule = maxLeadTime
				? currentMomentDate().add(maxLeadTime, 'minutes')
				: currentMomentDate();

			// Check if current time is below the store openning hours
			if (openningHours) {
				const openningDuration = moment.duration(
					minSchedule.diff(openningHours),
				);
				if (Math.round(openningDuration.asMinutes()) < 0) {
					// if minschedule is below the openning hours, set the default to store openning hours
					const [hrs, mins, secs] = merchant.storeHourStart.split(':');
					minSchedule = currentMomentDate()
						.set({
							hours: Number(hrs),
							minutes: Number(mins),
							seconds: Number(secs),
						})
						.add(maxLeadTime, 'minutes');
				}
			}

			// Now if computed minSchedule is beyond the closing store hours we default the minimum schedule to tomorrow with store's start hours + leadtime (minutes)
			if (closingHours) {
				const duration = moment.duration(closingHours.diff(minSchedule));
				if (
					duration.asMinutes() < 0 &&
					// Note that we add only 1 day if minSchedule is not day ahead the current date
					// this is to handle scenarios with 1 or 2 days leadtime
					duration.asDays() >= 0
				) {
					const [hrs, mins, secs] = merchant.storeHourStart.split(':');
					minSchedule = currentMomentDate()
						.set({
							hours: Number(hrs),
							minutes: Number(mins),
							seconds: Number(secs),
						})
						.add(1, 'days')
						.add(maxLeadTime, 'minutes');
				}
			}

			// Check the minimum schedule if day is allowable operating day..
			if (operatingDays && operatingDays.length >= 7) {
				for (let i = 0; i < 10; i++) {
					// if day is detected non operating days we do loopings up to allowable operating day
					if (operatingDays[minSchedule.day()] === '0') {
						minSchedule = minSchedule.add(1, 'days');
						const [hrs, mins, secs] = merchant.storeHourStart.split(':');
						minSchedule = minSchedule.set({
							hours: Number(hrs),
							minutes: Number(mins),
							seconds: Number(secs),
						});
					} else {
						break;
					}
				}
			}

			// We are rounding of the hours (e.g. 4:45pm will become to 5pm etc..)
			minSchedule =
				minSchedule.minute() > 0
					? minSchedule.add(1, 'hour').minutes(0).seconds(0)
					: minSchedule.minutes(0).seconds(0);

			// Now we check again if the generated minSchedule is equal or beyond the store closing hours
			// NOTE: ADDING OF DAYS ARE CONDUCTED ONLY IF CLOSING HOURS IS ABOVE THE OPENNING E.G. OPEN: 7AM - CLOSE: 5PM
			// BUT IF STORE SETTINGS IS: OPEN 9PM CLOSE 2AM, OPEN 7PM CLOSE 6AM, DAY WILL NOT ADD
			if (closingHours) {
				const [hrs, mins, secs] = merchant.storeHourStart.split(':');
				const [endHr, endMin, endSec] = merchant.storeHourEnd.split(':');
				const startHour = currentMomentDate().set({
					hours: Number(hrs),
					minutes: Number(mins),
					seconds: Number(secs),
				});
				const endHour = currentMomentDate().set({
					hours: Number(endHr),
					minutes: Number(endMin),
					seconds: Number(endSec),
				});

				const startEndDiff = moment.duration(endHour.diff(startHour)); // Check difference of store open vs close hours
				const duration = moment.duration(
					closingHours.diff(currentMomentDate()),
				); // Check difference of current date to closing hours
				if (duration?.asMinutes() <= 0 && startEndDiff.asMinutes() < 0) {
					const adjustedDate = currentMomentDate()
						.set({
							hours: Number(hrs),
							minutes: Number(mins),
							seconds: Number(secs),
						})
						.add(1, 'days');

					const diffToAdjust = moment.duration(adjustedDate.diff(minSchedule));
					minSchedule = (diffToAdjust.asMinutes() > 0
						? adjustedDate
						: minSchedule
					) // Check if previous computed "minSchedule" is greater than adjustedDate, we should choose "minSchedule"
						.set({
							hours: Number(hrs),
							minutes: Number(mins),
							seconds: Number(secs),
						});
				}
			}

			return {
				closingHours: closingHours?.toDate(),
				maxLeadTime,
				merchant,
				minSchedule: minSchedule?.toDate(),
				pickupDate: minSchedule?.toDate(), // Default
				currentDate: currentMomentDate().toDate(),
			};
		},
	);

	return checkoutSchedules;
};

// Generate store operating days string based on format -> 1:1:1:1:1:1:1
export const formatStoreOperatingDays = (
	operatingDaysString: string,
	formatType: number = 1,
) => {
	const DAYS = DAYS_LIST_FORMAT[formatType];
	let storeOpenDays = '';
	if (operatingDaysString) {
		operatingDaysString.split(':').map((day, ix) => {
			storeOpenDays += day === '1' ? `${DAYS[ix]}, ` : '';
			return day;
		});
		storeOpenDays = storeOpenDays.substr(0, storeOpenDays.length - 2);
		return storeOpenDays;
	}
	return '';
};

const getStoreOpenClosingMinsDiff = (date, merchant) => {
	const [startHr, startMin, startSecs] = merchant.storeHourStart.split(':');
	const startHour = moment(date).set({
		hours: Number(startHr),
		minutes: Number(startMin),
		seconds: Number(startSecs),
	});
	const [endHrs, endMins, endSecs] = merchant?.storeHourEnd.split(':');
	const storeClose = moment(date).set({
		hours: Number(endHrs),
		minutes: Number(endMins),
		seconds: Number(endSecs),
	});

	const startEndDiff = moment.duration(storeClose.diff(startHour));
	return startEndDiff.asMinutes();
};

// Returns error text if there error upon validating a date based on store settings
export const getOrderScheduleErrorText = (
	date: Date | null | undefined,
	scheduleSettings: CheckoutShippingScheduleProps,
) => {
	// 1. If date is below the minimum date (or minimum computed valid date) "The minimum valid date is "" as there's product need to prepare by the store
	// 2. If date is same on current and beyond closing hours
	// 3. If date is below the openning hours
	// 4. If date is not operating days
	if (!date) {
		return 'No date/time selected.';
	}

	if (!scheduleSettings) {
		return 'No schedule settings found in a store.';
	}

	const {merchant} = scheduleSettings;
	const isStoreOpenGreaterThanClosing =
		getStoreOpenClosingMinsDiff(date, merchant) > 0;

	// Validate if date is below the minimum computed schedule
	const minSchedule = moment(scheduleSettings.minSchedule);
	const minsScheduleDuration = moment.duration(moment(date).diff(minSchedule));
	if (Math.round(minsScheduleDuration.asMinutes()) < 0) {
		return `Date should not below the minimum estimated schedule (${minSchedule.format(
			'lll',
		)}), as order needs to prepare in ${convertMinutesToHoursDaysString(
			scheduleSettings.maxLeadTime || 0,
		)}.`;
	}

	// Validate if date is more than the maximum days difference
	if (Math.round(minsScheduleDuration.asDays()) > _MAX_DAYS_DIFF) {
		return `Date should not more than ${_MAX_DAYS_DIFF} day(s) difference on the current date.`;
	}

	// We validate only the openning and closing hours if open is less than closing hours
	// e.g. 7am - 5pm, 3pm - 11pm
	if (isStoreOpenGreaterThanClosing) {
		// Validate if date is below the store openning hours
		if (merchant?.storeHourStart) {
			const [hrs, mins, secs] = merchant?.storeHourStart.split(':');
			const storeOpen = moment(date).set({
				hours: Number(hrs),
				minutes: Number(mins),
				seconds: Number(secs),
			});
			const storeOpenDuration = moment.duration(moment(date).diff(storeOpen));
			if (Math.round(storeOpenDuration.asMinutes()) < 0) {
				return `Date should beyond the store openning hours (${storeOpen.format(
					'h:mma',
				)}).`;
			}
		}

		// Validate if date is beyond the store closing hours
		if (merchant?.storeHourEnd) {
			const [hrs, mins, secs] = merchant?.storeHourEnd.split(':');
			const storeClose = moment(date).set({
				hours: Number(hrs),
				minutes: Number(mins),
				seconds: Number(secs),
			});
			const storeOpenDuration = moment.duration(moment(date).diff(storeClose));
			// Validate if selected time is beyond closing hours
			if (Math.round(storeOpenDuration.asMinutes()) >= 0) {
				return `Please select time that are not beyond or equal the store closing hours (${storeClose.format(
					'h:mma',
				)}).`;
			}
		}
	}

	// Validate if date is day (e.g. Mon, Tue) is valid operating day
	if (merchant?.operatingDays) {
		const operatingDays = merchant.operatingDays.split(':');

		if (
			operatingDays.length &&
			operatingDays.length >= 7 &&
			operatingDays[moment(date).day()] !== '1'
		) {
			return `Store is not open on ${moment(date).format('dddd')}s.`;
		}
	}

	return null;
};
