
import React from "react";
import _ from "lodash";

// import SingleAutocompleteSelectField from "../SingleAutocompleteSelectField";
// import { useLocationSearchQuery } from "./queries";

// import { InputSelect } from "@components/molecules";
import { TypedSearchLocations } from "./queries";
import { AutoComplete, Button, Input } from "antd";
import { Icon } from "@temp/@next/components/atoms";
import { Loader } from "@components/atoms";
import './index.scss';
import powered_by_google from "../../images/powered_by_google.svg";
import { useAlert } from "react-alert";
const { Option } = AutoComplete;

export interface SelectedLocationData {
  name: string | null;
  placeId: string | null;
  city?: string | null;
}

export interface LocationAutocompleteProps {
  disabled?: boolean;
  errors?: any;
  name?: string;
  addressError?: boolean;
  defaultLabel?: string;
  label: string;
  setAddressError?: (value?: boolean) => void;
  defaultValue?: string | null;
  onChange?: (value: SelectedLocationData) => void;
  onLocationClicked?: (latitude: number, longitude: number) => void;
  onRemoveText?: () => void;
}

let delaySearch;
const AddressAutoComplete: React.FC<LocationAutocompleteProps> = props => {
  const {
    errors,
    name,
    label,
    defaultLabel,
    addressError,
    setAddressError,
    onChange,
    onLocationClicked,
    onRemoveText,
    defaultValue,
    ...rest
  } = props;

  const alert = useAlert();
  const [inputValue, setInputValue] = React.useState(defaultValue || "");
  const [textValue, setTextValue] = React.useState(defaultValue || "");
  const [loadingLocationBtn, setLoadingLocationBtn] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const renderOption = (item) => {
    return (
      <Option className="autocomplete-option" key={item.placeId} value={item.description}>
      </Option>
    );
  }

  const renderDropdown = (menu) => (
    <div className="autocomplete-menu">
      {menu}
      <div className="autocomplete-menu__dropdown">
        <img src={powered_by_google} />
      </div>
    </div>
  );

  const renderSuffix = () => {
    if (loadingLocationBtn) {
      return <div className="mx-2"><Loader /></div>
    }

    return (
      <>
        {/* {inputValue &&
          <Button
            onClick={() => {
              setInputValue('')
              if (onRemoveText) {
                onRemoveText()
              }
            }}
            className="autocomplete-button"
            type="primary"
          >
            <Icon size={20} color="#76aad3" name="x" viewBoxValue={'0 0 36 36'} />
          </Button>
        } */}
        <Button
          className="autocomplete-button"
          type="primary"
          onClick={() => {
            if (onLocationClicked) {
              setLoadingLocationBtn(true);
              navigator.permissions.query({ name: 'geolocation' })
                .then(status => {
                  if (status.state === 'denied') {
                    alert.error({
                      content: "Please allow JR Mall to access your current location.",
                      title: "Location access denied",
                    }, {
                      timeout: 2000,
                    });
                    setLoadingLocationBtn(false);
                  };
                  if (status.state === 'granted') {
                    navigator.geolocation.getCurrentPosition((position) => {
                      onLocationClicked(position.coords.latitude, position.coords.longitude);
                      setInputValue(defaultValue);
                      setTextValue(defaultValue);
                      setLoadingLocationBtn(false);
                    });
                  }
                })
            }
          }}
        >
          <Icon size={30} color="#383838" name="location" viewBoxValue={'0 0 45 45'} />
        </Button>
      </>
    );
  }

  const renderPrefix = () => (
    <i className="fas fa-map-marker-alt autocomplete-prefix"></i>
  );

  React.useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
      setTextValue(defaultValue);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    if (!textValue.length && !!setAddressError) {
      setAddressError(false);
    }
  }, [textValue]);

  return (
    <TypedSearchLocations variables={{ input: inputValue }}>
      {({ data, error, loading }) => {
        const locations = data && data.searchGeolocation ? data.searchGeolocation.filter(value => {
          const filteredValue = value.description.split(', ');
          const allowedAreas = ['Rizal', 'Baguio', 'Benguet', 'Bulacan', 'Cebu', 'Metro Manila', 'Cavite', 'Laguna']
          return allowedAreas.some(area => area.includes(filteredValue[filteredValue.length - 2]))
        }) : [];
        const isDataLoading = loading || isLoading;
        return (
          <AutoComplete className="autocomplete" style={{ width: '100%' }}
            size="large"
            dropdownClassName="autocomplete-dropdown"
            notFoundContent={isDataLoading ? "Loading..." : "Location not found or no item entered on search box."}
            dataSource={isDataLoading ? [] : locations.map(renderOption)}
            defaultValue={defaultValue || inputValue}
            value={textValue}
            onChange={(value: any) => {
              if (setAddressError) {
                setAddressError(true);
              }
              setTextValue(value);
              setLoading(true);
              clearTimeout(delaySearch);
              delaySearch = setTimeout(() => {
                setInputValue(value);
                setLoading(false)
              }, 1000);
            }}
            onSelect={(value: any) => {
              const selected = locations.find(item => item?.description === value);
              if (onChange) {
                onChange({
                  name: selected?.description,
                  placeId: selected?.placeId,
                  city: selected?.city
                });
              }
              if (setAddressError) {
                setAddressError(false);
              }
            }}
            dropdownRender={(menu) => renderDropdown(menu)}
            {...rest}
          >
            <Input
              placeholder={defaultLabel || 'Add a new address'}
              className="autocomplete-input"
              size='large'
              required
              prefix={renderPrefix()}
              style={{ padding: '0 0 0 10px', height: '50px', borderRadius: '5px' }}
              suffix={renderSuffix()}
            />
          </AutoComplete>
        )
      }}
    </TypedSearchLocations>
  );
};

export default AddressAutoComplete;

