import gql from 'graphql-tag';
import {TypedQuery} from 'src/core/queries';
import {
	BrandCollections,
	BrandCollectionsVariables,
} from './gqlTypes/BrandCollections';

const basicBrandCollectionFragment = gql`
	fragment BasicBrandCollectionFields on BrandCollection {
		id
		name
		slug
		description
		publicationDate
		isPublished
		backgroundImage {
			alt
			url
		}
	}
`;

const basicMerchantBrandFragment = gql`
	fragment BasicMerchantBrandFields on MerchantBrand {
		id
		name
		description
		status
		slug
		logo {
			url
		}
		banner(size: 350) {
			url
		}
	}
`;

const brandCollectionsQuery = gql`
	${basicBrandCollectionFragment}
	${basicMerchantBrandFragment}
	query GetBrandCollections($longitude: Float, $latitude: Float) {
		brandCollections(first: 20, filter: {published: PUBLISHED}) {
			totalCount
			edges {
				node {
					...BasicBrandCollectionFields
					merchantBrands(
						first: 10
						# sortBy: { field:BRAND_COLLECTION_ITEM_ID, direction: ASC }
						longitude: $longitude
						latitude: $latitude
					) {
						edges {
							node {
								...BasicMerchantBrandFields
								merchantBrandMerchantCategoryAssignment(first: 3) {
									edges {
										node {
											merchantCategory {
												id
												name
											}
										}
									}
								}
							}
						}
					}
				}
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
		}
	}
`;
export const TypedBrandCollectionsQuery = TypedQuery<
	BrandCollections,
	BrandCollectionsVariables
>(brandCollectionsQuery);
