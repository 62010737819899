import gql from 'graphql-tag';
import {TypedQuery} from 'src/core/queries';
import {
	BrandCollections,
	BrandCollectionsVariables,
} from './gqlTypes/BrandCollection';

// FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.

const basicMerchantBrandFragment = gql`
	fragment BasicMerchantBrandFields on MerchantBrand {
		id
		name
		description
		status
		slug
		logo {
			url
		}
		banner(size: 350) {
			url
		}
	}
`;

const singleBrandCollectionQuery = gql`
	${basicMerchantBrandFragment}
	query GetBrandCollection(
		$slug: String
		$after: String
		$pageSize: Int
		$longitude: Float
		$latitude: Float
	) {
		brandCollection(slug: $slug) {
			id
			name
			merchantBrands(
				first: $pageSize
				after: $after
				longitude: $longitude
				latitude: $latitude
			) # sortBy: { field:BRAND_COLLECTION_ITEM_ID, direction: ASC }
			{
				edges {
					node {
						...BasicMerchantBrandFields
						merchantBrandMerchantCategoryAssignment(first: 5) {
							edges {
								node {
									merchantCategory {
										id
										name
									}
								}
							}
						}
					}
				}
				pageInfo {
					endCursor
					hasNextPage
					hasPreviousPage
					startCursor
				}
				totalCount
			}
		}
	}
`;
export const TypedBrandCollectionQuery = TypedQuery<
	BrandCollections,
	BrandCollectionsVariables
>(singleBrandCollectionQuery);
