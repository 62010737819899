import * as React from "react";

import { MetaWrapper } from "src/components";
import Page from './Page';
import { TypedBrandCollectionQuery } from "./queries";
import { RouteComponentProps } from "react-router";
import { useLayout } from "@temp/layout/hooks/useLayout";

const View: React.FC<RouteComponentProps<{ slug: string }>> = ({ match }) => {
    const { useUserLocation } = useLayout();

    return (
        <TypedBrandCollectionQuery
            errorPolicy="all"
            loaderFull
            variables={{
                slug: match.params.slug,
                pageSize: 50,
                after: "",
                latitude: useUserLocation()?.latitude || 0,
                longitude: useUserLocation()?.longitude || 0
            }}
            key={match.params.slug}
        >
            {({ data, loading, loadMore }) => {
                const handleLoadMore = () => {
                    loadMore(
                        (prev, next) => ({
                            ...prev,
                            brandCollection: {
                                ...prev?.brandCollection,
                                merchantBrands: {
                                    ...prev?.brandCollection?.merchantBrands,
                                    edges: [...prev.brandCollection?.merchantBrands.edges, ...next.brandCollection?.merchantBrands.edges],
                                    pageInfo: next.brandCollection?.merchantBrands.pageInfo,
                                }
                            }
                        }),
                        { after: data.brandCollection?.merchantBrands.pageInfo.endCursor }
                    );
                }
                const { brandCollection } = data || {};

                return (
                    <MetaWrapper meta={{}}>
                        <Page
                            brandCollection={brandCollection}
                            canLoadMore={brandCollection?.merchantBrands?.pageInfo?.hasNextPage}
                            loading={loading}
                            onLoadMore={handleLoadMore}
                        />
                    </MetaWrapper>
                );
            }}
        </TypedBrandCollectionQuery>
    );
};

export default View;