import React from "react";
import { SubDetails } from "@components/containers";
// import { Radio } from "@components/atoms";
import { Select, Input} from 'antd';
import * as S from "./styles";

import "@styles/datetimePicker.css";
import "./scss/index.scss";

import moment from "moment";

const { Option } = Select;

export const TIME_FORMAT = "HH:mm:ss";

interface IProps {
    minDate: any,
    pickDate: any,
    startTime: string;
    endTime: string;
    value: string;
    onChange: (e: any) => void;
}

const initOptions = (
    date: any, 
    minDate: any, 
    startTime: string, 
    endTime: string
) => {
    const minDatetime = moment(minDate);
    const dateStr = moment(date).format('YYYY-MM-DD');
    let timeStart = moment(`${dateStr} ${startTime}`);
    const timeEnd = moment(`${dateStr} ${endTime}`);
    
    // If selected date is today, we should get the current time and compute it based on settings (e.g. buffer time etc...)
    if (dateStr === minDatetime.format('YYYY-MM-DD')) {
        timeStart = (minDatetime.minute() > 0)
            ? minDatetime.add(1, 'hour')
            : minDatetime;
    }

    // The time selection should be exact per hour e.g. 12am-1am, 1am-2am etc...
    if (timeStart.minute() > 0) {
        timeStart = timeStart.minutes(0).seconds(0);
    }

    let hoursDiff = moment.duration(timeEnd.diff(timeStart)).asHours();

    // This happen if closing hours is less than openning hours e.g. open 8am, close 3am or open 4pm close 12am
    if (hoursDiff < 0) {
        hoursDiff = moment.duration(timeEnd.add(1, 'days').diff(timeStart)).asHours()
    }

    const options = [{
        id: timeStart.format(TIME_FORMAT),
        text: `${timeStart.format('LT')} - ${timeStart.add(1, 'hour').format('LT')}`,
    }];

    for (let hr = 0; hr < (hoursDiff - 1); hr++) {
        const dateTime = moment(timeStart).add(hr, 'hour');
        options.push({
            id: dateTime.format(TIME_FORMAT),
            text: `${dateTime.format('LT')} - ${dateTime.add(1, 'hour').format('LT')}`,
        });
    }

    return options;
}

const TimeOptions: React.FC<IProps> = ({
    minDate,
    pickDate,
    startTime,
    endTime,
    onChange,
    value,
}: IProps) => {
    const windowSize =  window.onresize = () => {
        return window.innerWidth <= 540;
    };
    const options = initOptions(pickDate, minDate, startTime, endTime);
    const defaultValue = moment(minDate).format(TIME_FORMAT);

    return (
        <Input.Group compact style={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center', height: 40, width: '100%' ,marginBottom: '0.5rem', marginTop: '1rem'}}>
            <S.IconContainer>
            <SubDetails
                items={[
                    {
                        iconName: 'far fa-clock',
                        iconSize: 20,
                        paddingRight: windowSize() ? 0 : 3,
                        text: ' '
                    },
                ]}
            />
            </S.IconContainer>
            <Select 
                defaultValue={defaultValue}
                size={'large'} 
                showArrow 
                allowClear 
                placeholder={'Select your delivery time'}
                onChange={onChange}
                value={value || defaultValue}
            >
                {
                    options.map(option => (
                        <Option value={option.id}>{option.text}</Option>
                    ))
                }
            </Select>
        </Input.Group>
    );
};

export default TimeOptions;