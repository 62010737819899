import * as React from "react";
import { TypedGetNearbyStoresQuery } from "./queries";
import { getDistanceFromLatLonInKm, convertMinutesToHoursDaysString } from "src/helpers";
import { NearbyStoresMerchant, NearbyStores } from "./gqlTypes/NearbyStores";

interface ResultProps {
    nearByStores: NearbyStoresMerchant[];
    loading: boolean;
    loadMore: () => void;
    hasNextPage: boolean;
}

interface Props {
    latitude: number;
    longitude: number;
    placeId: string;
    children: (
        result: ResultProps
    ) => React.ReactNode;
    onCompleted?: (data: NearbyStores) => void
}

const NearbyStoresQuery: React.FC<Props> = ({
    latitude,
    longitude,
    children,
    placeId,
    onCompleted
}) => {
    const [nearByStores, setNearByStores] = React.useState([]);
    const [count, setCount] = React.useState(0);
    return (
        <TypedGetNearbyStoresQuery
            errorPolicy="all"
            variables={{
                first: 50,
                after: "",
                latitude: latitude || 0,
                longitude: longitude || 0,
                placeId: placeId || ""
            }}
            onCompleted={data => {
                const myNearStores = (data?.nearbyStores?.storesNearby?.edges || []).map(item => {
                    const distanceRadius = getDistanceFromLatLonInKm(
                        latitude || 0,
                        longitude || 0,
                        item?.node?.primaryAddress?.latitude || 0,
                        item?.node?.primaryAddress?.longitude || 0
                    );

                    return {
                        ...item?.node,
                        distanceRadius,
                        distance: `${distanceRadius}km`,
                        preparationTime: convertMinutesToHoursDaysString(item?.node?.leadTime || 0),
                        categories: (item?.node?.merchantBrandCategoryAssignment?.edges || [])
                            .map(item => item?.node?.merchantCategory?.name || "")
                            .join(", ")
                    }
                })
                    .filter(merchant => merchant?.distanceRadius <= 100)
                    .sort((merchantA, merchantB) => (merchantA?.distanceRadius - merchantB?.distanceRadius))
                    .slice(0, 12 * (count + 1));

                setNearByStores(myNearStores);

                if (onCompleted) {
                    onCompleted(data);
                }
            }}
        >
            {({ data: nearStoresData, loading, loadMore: nearStoresLoadMore }) => {
                const loadMore = () => {
                    setCount(prev => prev + 1);
                    nearStoresLoadMore(
                        (prev, next) => ({
                            ...prev,
                            nearbyStores: {
                                ...prev?.nearbyStores,
                                storesNearby: {
                                    ...prev?.nearbyStores.storesNearby,
                                    edges: [...prev?.nearbyStores?.storesNearby.edges, ...next?.nearbyStores?.storesNearby.edges],
                                    pageInfo: next?.nearbyStores?.storesNearby.pageInfo,
                                },
                            }
                        }),
                        { after: nearStoresData?.nearbyStores?.storesNearby?.pageInfo.endCursor }
                    );
                };

                const hasNextPage = nearStoresData?.nearbyStores?.storesNearby?.pageInfo?.hasNextPage;

                return children({
                    nearByStores,
                    loading,
                    loadMore,
                    hasNextPage
                })
            }}
        </TypedGetNearbyStoresQuery>
    );
};

export default NearbyStoresQuery;