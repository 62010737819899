import gql from 'graphql-tag';
import {TypedQuery} from 'src/core/queries';
import {StoreDetails, StoreDetailsVariables} from './gqlTypes/StoreDetails';
import {productPricingFragment, priceFragment} from 'src/views/Product/queries';
import {
	ProductDetails,
	ProductDetailsVariables,
} from './gqlTypes/ProductDetails';

export const basicProductFragment = gql`
	fragment BasicProductFields on Product {
		id
		name
		leadTime
		category {
			id
			name
		}
		thumbnail {
			url
			alt
		}
		thumbnail2x: thumbnail(size: 510) {
			url
		}
	}
`;

export const basicProductMerchantFragment = gql`
	fragment BasicProductMerchantFields on MerchantProduct {
		id
		name
		leadTime
		category {
			id
			name
		}
		thumbnail {
			url
			alt
		}
		thumbnail2x: thumbnail(size: 510) {
			url
		}
	}
`;

export const productMerchantPricingFragment = gql`
	${priceFragment}
	fragment ProductMerchantPricingField on MerchantProduct {
		pricing {
			onSale
			priceRangeUndiscounted {
				start {
					...Price
				}
				stop {
					...Price
				}
			}
			priceRange {
				start {
					...Price
				}
				stop {
					...Price
				}
			}
		}
	}
`;

export const productVariantFragment = gql`
	${priceFragment}
	fragment ProductVariantFields on ProductVariant {
		id
		sku
		name
		isAvailable
		images {
			id
			url
			alt
		}
		pricing {
			onSale
			priceUndiscounted {
				...Price
			}
			price {
				...Price
			}
		}
		attributes {
			attribute {
				id
				name
				slug
			}
			values {
				id
				name
				value: name
			}
		}
	}
`;

// FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.
export const storeDetailsQuery = gql`
	${basicProductFragment}
	${productPricingFragment}
	${basicProductMerchantFragment}
	${productMerchantPricingFragment}
	query VariantList(
		$slug: String!
		$productAfter: String
		$productPageSize: Int
		$collectionsPageSize: Int
		$collectionsProductPageSize: Int
	) {
		merchantBySlug(slug: $slug) {
			id
			name
			description
			storeTitle
			storeDescription
			storeUrl
			storeTrademark
			leadTime
			storeHourStart
			storeHourEnd
			operatingDays
			slug
			status
			hasAdultContent
			merchantBrand {
				id
				name
				slug
			}
			logo {
				url
			}
			banner {
				url
			}
			primaryAddress {
				id
				streetAddress1
				streetAddress2
				latitude
				longitude
			}
			merchantBrandCategoryAssignment(first: 1) {
				edges {
					node {
						id
						merchantCategory {
							id
							name
							slug
						}
					}
				}
			}
			products(after: $productAfter, first: $productPageSize) {
				totalCount
				edges {
					node {
						...BasicProductMerchantFields
						...ProductMerchantPricingField
					}
				}
				pageInfo {
					endCursor
					hasNextPage
					hasPreviousPage
					startCursor
				}
			}
			productCollections(first: $collectionsPageSize) {
				edges {
					node {
						id
						name
						sortOrder
						products(first: $collectionsProductPageSize) {
							edges {
								node {
									...BasicProductFields
									...ProductPricingField
								}
							}
						}
					}
				}
			}
		}
	}
`;
export const TypedStoreDetailsBySlugQuery = TypedQuery<
	StoreDetails,
	StoreDetailsVariables
>(storeDetailsQuery);

export const productDetailsQuery = gql`
	${basicProductFragment}
	${productVariantFragment}
	${productPricingFragment}
	query ProductDetails($id: ID!) {
		product(id: $id) {
			...BasicProductFields
			...ProductPricingField
			descriptionJson
			category {
				id
				name
			}
			images {
				id
				url
			}
			variants {
				...ProductVariantFields
			}
			seoDescription
			seoTitle
			isAvailable
		}
	}
`;
export const TypedProductDetailsQuery = TypedQuery<
	ProductDetails,
	ProductDetailsVariables
>(productDetailsQuery);
