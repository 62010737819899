import React from "react";
// import { Checkbox } from "@components/atoms";
// import { filterNotEmptyArrayItems } from "@utils/misc";
// import { AddressForm } from "../AddressForm";
// import { AddressGridSelector } from "../AddressGridSelector";
import { Radio } from 'antd';

// import { IDiscountFormData } from "../DiscountForm/types";
// import { PaymentGatewaysList } from "../PaymentGatewaysList";
// import { DiscountForm } from "../DiscountForm";

import * as S from "./styles";
import { IProps } from "./types";
// import { InputSelect } from "@components/molecules";
// import {
//   ISelectedPaymentTransactionType
// } from "@types";
import "./scss/index.scss";

import mastercard from "images/mastercard-icon.png";
import visa from "images/visa-icon.png";
import jcb from "images/jcb-icon.png";
import jrpay from "images/JR_Pay_Purple.png";
import paymaya from "images/paymaya-icon.png";
import { useUserDetails } from "@saleor/sdk";
import { PAYMENT_TYPE_CODES } from "src/constants";

/**
 * Payment options used in checkout.
 */
const CheckoutPayment: React.FC<IProps> = ({
  gatewayErrors,
  billingErrors,
  promoCodeErrors,
  selectedUserAddressId,
  userAddresses,
  billingAsShippingAddress = false,
  checkoutBillingAddress,
  countries,
  billingFormRef,
  billingFormId,
  paymentGateways,
  setBillingAddress,
  billingAsShippingPossible,
  setBillingAsShippingAddress,
  promoCodeDiscountFormId,
  promoCodeDiscountFormRef,
  promoCodeDiscount,
  addPromoCode,
  removeVoucherCode,
  submitUnchangedDiscount,
  selectedPaymentGateway,
  selectedPaymentGatewayToken,
  selectPaymentGateway,
  gatewayFormRef,
  gatewayFormId,
  userId,
  newAddressFormId,
  processPayment,
  onGatewayError,
  paymentTransactionType,
  selectedPaymentTransactionType,
  paymentTransactionTypeOptions = [],
  // user,
}: IProps) => {
  // const { checkout } = useCheckout();
  const { data: user } = useUserDetails();

  // const checkoutShippingAddress = checkout?.shippingAddress
  //   ? {
  //       ...checkout?.shippingAddress,
  //       phone: checkout?.shippingAddress?.phone || undefined,
  //     }
  //   : undefined;
  // const [showPromoCodeForm, setShowPromoCodeForm] = useState(
  //   !!promoCodeDiscount?.voucherCode
  // );

  // useEffect(() => {
  //   const isVoucherCode = !!promoCodeDiscount?.voucherCode;
  //   if (isVoucherCode) {
  //     setShowPromoCodeForm(isVoucherCode);
  //   }
  // }, [promoCodeDiscount?.voucherCode]);

  // const handleChangeShowPromoCodeForm = () => {
  //   setShowPromoCodeForm(!showPromoCodeForm);
  // };

  // const handleSubmitPromoCode = (discountForm?: IDiscountFormData) => {
  //   const newPromoCode = discountForm?.promoCode;
  //   const savedPromoCode = promoCodeDiscount?.voucherCode;

  //   if ((!newPromoCode || !showPromoCodeForm) && savedPromoCode) {
  //     removeVoucherCode(savedPromoCode);
  //   } else if (newPromoCode && newPromoCode !== savedPromoCode) {
  //     addPromoCode(newPromoCode);
  //   } else {
  //     submitUnchangedDiscount();
  //   }
  // };
  // const [value, setValue] = React.useState(1);

  // const { data: user } = useUserDetails();

  // const adresses =
  //   userAddresses?.filter(filterNotEmptyArrayItems).map(address => ({
  //     address: {
  //       ...address,
  //       isDefaultBillingAddress: address.isDefaultBillingAddress || false,
  //       isDefaultShippingAddress: address.isDefaultShippingAddress || false,
  //       phone: address.phone || undefined,
  //     },
  //     id: address?.id || "",
  //     onSelect: () => null,
  //   })) || [];
  
  // const defaultValue = paymentTransactionTypeOptions ? paymentTransactionTypeOptions[0] : null;

  React.useEffect(()=>{
    selectedPaymentTransactionType(paymentTransactionTypeOptions[0]);
  },[]);
  
  return (
    <S.Wrapper>
      <section>
      {/* <CheckoutCard checkoutDetails={checkoutShippingAddress}/> */}
        {/* <CheckoutCard/> */}
        <S.Title data-test="checkoutPageSubtitle">Payment Method</S.Title>
        <S.FieldContainer>
        {/* <InputSelect
          defaultValue={defaultValue}
          label="Payment Service"
          name="payment"
          options={user ? paymentTransactionTypeOptions : [defaultValue]}
          value={paymentTransactionType || defaultValue}
          // onChange={(value: any, name: any) => setFieldValue(name, value)}
          onChange={(selectedPayment: ISelectedPaymentTransactionType) => selectedPaymentTransactionType(selectedPayment)}
          optionLabelKey="display"
          optionValueKey="code"
          // errors={fieldErrors!.country}
          // autoComplete="country"
        /> */}
        </S.FieldContainer>
        <Radio.Group 
          style={{width: '100%'}} 
          value={paymentTransactionType?.code}
          onChange={(e) => {
            const paymentMode = paymentTransactionTypeOptions?.find(mode => mode.code === e.target.value);
            // @ts-ignore
            selectedPaymentTransactionType(paymentMode)
          }}
        >
          <div className="checkout-payment__radioGroup">
            <Radio 
              className="checkout-payment__radio"
              value={PAYMENT_TYPE_CODES.PAYMAYA_AUTO_CAPTURE}
            >
                <div className="checkout-payment__card">
                  <p>Credit Card / Debit Card / PayMaya</p>
                  <div className="checkout-payment__logo">
                  <img src={visa} />
                  <img src={mastercard} />
                  <img src={jcb} />
                  <img src={paymaya} />
                  </div>
                </div>
            </Radio>
            <div className="checkout-payment__note">
              <p>Once you click confirm order, you will be redirected to our Payment Portal. Kindly enter your card details to proceed with payment. </p>
            </div>
          </div>
          {user &&
          <div className="checkout-payment__radioGroup">
            <Radio 
              className="checkout-payment__radio" 
              value={PAYMENT_TYPE_CODES.JR_PAY}
            >
              <div className="checkout-payment__card">
                <p>JR Pay</p>
                <div className="checkout-payment__jrPayLogo">
                <img src={jrpay} />
                </div>
              </div>
            </Radio>
            <div className="checkout-payment__note">
              <p>Once you click confirm order, you will be redirected to our JR Pay Portal. Kindly enter the OTP that will be sent to your registered mobile number. </p>
            </div>
          </div>}
        </Radio.Group>
      </section>
      {/* <section>
        <S.Title data-test="checkoutPageSubtitle">Billing Address</S.Title>
        {billingAsShippingPossible && (
          <S.FieldContainer>
          <Checkbox
            data-test="checkoutPaymentBillingAsShippingCheckbox"
            name="billing-same-as-shipping"
            checked={billingAsShippingAddress}
            onChange={() =>
              setBillingAsShippingAddress(!billingAsShippingAddress)
            }
          >
            Same as shipping address
          </Checkbox>
          </S.FieldContainer>
        )}
        <S.AdditionalText>Note: Uncheck the box to input a different billing address </S.AdditionalText>
        {!billingAsShippingAddress && (
          <>
            {billingAsShippingPossible && <S.Divider />}
            {userAddresses ? (
              <AddressGridSelector
                formId={billingFormId}
                formRef={billingFormRef}
                addresses={adresses}
                selectedAddressId={selectedUserAddressId}
                countriesOptions={countries?.filter(filterNotEmptyArrayItems)}
                userId={userId}
                errors={billingErrors}
                onSelect={(address, id) =>
                  setBillingAddress(address, undefined, id)
                }
                newAddressFormId={newAddressFormId}
              />
            ) : (
              <AddressForm
                testingContext="billingAddressForm"
                formId={billingFormId}
                formRef={billingFormRef}
                countriesOptions={countries.filter(filterNotEmptyArrayItems)}
                address={checkoutBillingAddress || undefined}
                handleSubmit={address =>
                  setBillingAddress(address, address?.email)
                }
                includeEmail={!billingAsShippingPossible}
                errors={billingErrors}
              />
            )}
          </>
        )}
        
      </section> */}
      {/* <S.Divider />
      <section>
        <S.Title data-test="checkoutPageSubtitle">PAYMENT METHOD</S.Title>
        <Checkbox
          data-test="checkoutPaymentPromoCodeCheckbox"
          name="payment-promo-code"
          checked={showPromoCodeForm}
          onChange={handleChangeShowPromoCodeForm}
        >
          Do you have a gift card voucher or discount code?
        </Checkbox>
        {showPromoCodeForm && (
          <S.DiscountField>
            <DiscountForm
              discount={{ promoCode: promoCodeDiscount?.voucherCode }}
              formId={promoCodeDiscountFormId}
              formRef={promoCodeDiscountFormRef}
              handleSubmit={handleSubmitPromoCode}
              errors={promoCodeErrors}
            />
          </S.DiscountField>
        )}
        <S.Divider />
        <PaymentGatewaysList
          errors={gatewayErrors}
          paymentGateways={paymentGateways}
          formRef={gatewayFormRef}
          formId={gatewayFormId}
          processPayment={processPayment}
          selectedPaymentGateway={selectedPaymentGateway}
          selectedPaymentGatewayToken={selectedPaymentGatewayToken}
          selectPaymentGateway={selectPaymentGateway}
          onError={onGatewayError}
        />
      </section> */}
    </S.Wrapper>
  );
};

export { CheckoutPayment };
