import React, {FC, useState} from 'react';
import { 
	getDistanceFromLatLonInKm, 
	convertMinutesToHoursDaysString, 
	numberWithCommas,
	getHasAdultContent,
	sorter,
} from "src/helpers";
import './index.scss';
import moment from "moment";
import Banner from '../Branch/Banner';
import BranchInfo from '../Branch/BranchInfo';
import StoreCategories from '../Branch/StoreCategories';
import Featured from '../Branch/Featured';
import Products from '../Branch/Products';
import { StoreDetailsMerchantBySlug, StoreProductsEdgesNode } from "./gqlTypes/StoreDetails";
import { useLayout } from '@temp/layout/hooks/useLayout';
import NoProduct from '@temp/views/Store/StoreProducts/NoProduct';
import { Breadcrumb } from "@temp/components";
import BranchModal from '../Branch/BranchModal/BranchModal';
import WarningModal from '../Branch/WarningModal/WarningModal';


interface BranchSelectProps {
	// categories?: string[];
	// totalCount?: number;
	// // merchantBrand: MerchantBrandData | null;
	// canLoadMore?: boolean;
	// loading?: boolean;
	// onLoadMore?: () => void;

	merchant: StoreDetailsMerchantBySlug;
	hasProductNextPage: boolean;
	isProductLoading: boolean;
	setProductOverlay: (state: boolean, id?: string) => void;
	productLoadMore: () => void;
	breadcrumbs: Breadcrumb[];
}

const buildProductResult = (node: StoreProductsEdgesNode) => {
	// const currency = node?.pricing?.priceRange?.start?.gross?.currency || "PHP";
	const priceStart = node?.pricing?.priceRange?.start?.gross?.amount;
	const priceEnd = node?.pricing?.priceRange?.stop?.gross?.amount;
	const price = priceStart === priceEnd
					? `₱ ${numberWithCommas(priceStart)}`
					: `₱ ${numberWithCommas(priceStart)} - ${numberWithCommas(priceEnd)}`

	return {
		id: node?.id,
		name: node?.name,
		imageUrl: node?.thumbnail?.url,
		categoryName: node?.category?.name,
		prepareTime: convertMinutesToHoursDaysString(node?.leadTime),
		price
	}
}

const defaultMenu = { id: "", name: "All", products: [] };

const BranchPage: FC<BranchSelectProps> = ({
	merchant,
	hasProductNextPage,
	isProductLoading,
	breadcrumbs,
	productLoadMore,
	setProductOverlay
}) => {
	const { merchantBrandCategoryAssignment, storeHourStart, storeHourEnd, operatingDays, primaryAddress, storeDescription, description, hasAdultContent } = merchant || {};
	const { useUserLocation } = useLayout();
	const { latitude, longitude } = useUserLocation() || {};
	const [selectedMenuId, setSelectedMenuId] = useState(defaultMenu?.id);
	const [showBranchInfo, setShowBranchInfo] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const isSelectedMenuIsDefault = defaultMenu?.id === selectedMenuId;
	const categories = (merchantBrandCategoryAssignment?.edges || []).map(item => item?.node?.merchantCategory?.name);
	const distance = (!!latitude && !!longitude)
					? getDistanceFromLatLonInKm(
						latitude || 0,
						longitude || 0,
						merchant?.primaryAddress?.latitude || 0,
						merchant?.primaryAddress?.longitude || 0,
					) : null;
	
	const products = merchant?.products?.edges.map(data => {
		return buildProductResult(data?.node);
	}) || [];
	const collections = merchant?.productCollections?.edges.map(data => {
		const products = isSelectedMenuIsDefault
						? data?.node?.products?.edges.slice(0, 10).map(pData => buildProductResult(pData?.node))
						: data?.node?.products?.edges.map(pData => buildProductResult(pData?.node));

		return {
			id: data?.node?.id,
			name: data?.node?.name,
			sortOrder: data?.node?.sortOrder,
			products
		}
	}) || [];

	// const selectedCollection = isSelectedMenuIsDefault
	// 						? null
	// 						: collections.find(collection => collection?.id === selectedMenuId);
	
	const storeOpen =
	storeHourStart && moment(storeHourStart, "HH:mm:ss").format("h:mma");
	const storeClose =
	storeHourEnd && moment(storeHourEnd, "HH:mm:ss").format("h:mma");

	React.useEffect(()=>{
		document.body.style.overflowY = showBranchInfo || showWarning ? 'hidden' : 'auto';
	},[showBranchInfo, showWarning]);

	React.useEffect(()=>{
		if(hasAdultContent) {
			setShowWarning(!getHasAdultContent());
		}
	},[hasAdultContent]);

	return (
		<div className="branch-page-main">
			<Banner 
				imgUrl={merchant?.banner?.url || ""}
				setShowBranchInfo={setShowBranchInfo}
			/>
			<BranchInfo 
				name={merchant?.name || merchant?.storeTitle}
				imageUrl={merchant?.logo?.url}
				distance={distance}
				time={convertMinutesToHoursDaysString(merchant?.leadTime || 0)}
				categories={categories}
				breadcrumbs={breadcrumbs}
				storeOpen={storeOpen}
				storeClose={storeClose}
				storeHourStart={storeHourStart}
				storeHourEnd={storeHourEnd}
				operatingDays={operatingDays}
				streetAddress1={primaryAddress?.streetAddress1}
			/>
			<StoreCategories 
				collections={[...[defaultMenu], ...collections]}
				selectedCollectionId={selectedMenuId}
				onSelectChange={(id) => setSelectedMenuId(id)}
			/>
			<div className="main-container py-4" style={{overflow:'hidden'}}>
				{/* {
				!!selectedCollection ?
					<Products
						setProductOverlay={setProductOverlay}
						products={selectedCollection?.products}
						title={selectedCollection?.name}
					/>
				: */}
				<>
					{sorter(collections).map((data, index) => (
						<Featured
							setProductOverlay={setProductOverlay}
							name={data?.name}
							products={data?.products || []}
							index={index}
						/>
					))}
					{products.length !== 0 ?
					<Products
						setProductOverlay={setProductOverlay}
						products={products}
						loading={isProductLoading}
						canLoadMore={hasProductNextPage}
						onLoadMore={productLoadMore}
						title={collections.length > 0 ? "All Products" : "Products"}
					/>
					: <NoProduct/>}
				</>
				{/* } */}
			</div>
			{showBranchInfo && 
				<BranchModal
					distance={distance}
					description={storeDescription || description || ""}
					time={convertMinutesToHoursDaysString(merchant?.leadTime || 0)}
					storeOpen={storeOpen}
					storeClose={storeClose}
					storeHourStart={storeHourStart}
					storeHourEnd={storeHourEnd}
					operatingDays={operatingDays}
					name={merchant?.storeTitle || ""}
					bannerUrl={merchant?.banner?.url || ""}
					avatarUrl={merchant?.logo?.url}
					categories={categories || []} 
					breadcrumbs={breadcrumbs || []}
					streetAddress1={primaryAddress?.streetAddress1 || ""}
					setShowBranchInfo={setShowBranchInfo}

				/>
			}
			{showWarning && <WarningModal hasAdultContent={hasAdultContent} setShowWarning={setShowWarning}/>}
		</div>
	);
};

export default BranchPage;
