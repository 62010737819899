import React, { useCallback } from "react";

import { TextField } from "@components/molecules";
// import { InputSelect } from "@components/molecules";

import * as S from "./styles";
import { PropsWithFormik } from "./types";
import powered_by_google from "images/powered_by_google.svg";

// import LocationAutocomplete from "../../../../components/LocationAutocomplete";
import AddressAutoComplete from '@temp/components/AddressAutocomplete';

export const AddressFormContent: React.FC<PropsWithFormik> = ({
  formRef,
  handleChange,
  handleBlur,
  formId,
  errors,
  handleSubmit,
  values,
  countriesOptions,
  defaultValue,
  setFieldValue,
  testingContext,
  includeEmail = false,
}) => {
  const basicInputProps = useCallback(
    () => ({ onBlur: handleBlur, onChange: handleChange }),
    [handleChange, handleBlur]
  );

  const fieldErrors: any = {};

  if (errors && errors?.length) {
    errors.map(({ field, message }: { field: string; message: string }) => {
      fieldErrors[field] = fieldErrors[field]
        ? [...fieldErrors[field], { message }]
        : [{ message }];
    });
  }
  
  const defaultStreetAddress1: any = values && values.streetAddress1 ? {
    description: values.streetAddress1, placeId: ""
  } : null;

  return (
    <S.AddressForm
      id={formId}
      ref={formRef}
      onSubmit={handleSubmit}
      data-test={testingContext}
    >
      <S.Wrapper>
        <S.RowWithTwoCells>
          <TextField
            name="firstName"
            label="First Name"
            value={values!.firstName}
            autoComplete="given-name"
            errors={fieldErrors!.firstName}
            borderRadius={5}
            {...basicInputProps()}
          />
          <TextField
            name="lastName"
            label="Last Name"
            value={values!.lastName}
            autoComplete="family-name"
            errors={fieldErrors!.lastName}
            borderRadius={5}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <TextField
            name="companyName"
            label="Company Name (Optional)"
            value={values!.companyName}
            autoComplete="organization"
            errors={fieldErrors!.companyName}
            borderRadius={5}
            {...basicInputProps()}
          />
          <TextField
            name="phone"
            label="Mobile Number (09xxxxxxxxx)"
            value={values!.phone}
            pattern="^(09|\+639)\d{9}$"
            autoComplete="tel"
            errors={fieldErrors!.phone}
            borderRadius={5}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithOneCell>
          {/* <TextField
            name="streetAddress1"
            label="Address line 1"
            value={values!.streetAddress1}
            autoComplete="address-line1"
            errors={fieldErrors!.streetAddress1}
            {...basicInputProps()}
          /> */}

          <AddressAutoComplete
            label="Address Pin (Click and Type to Search)"
            name="streetAddress1"
            errors={fieldErrors!.streetAddress1}
            defaultValue={defaultStreetAddress1?.description}
            onChange={(value) => {
              setFieldValue("streetAddress1", value?.name || "");
              setFieldValue("placeId", value?.placeId || "");
              setFieldValue("city", value?.city || "");
              
              // @ts-ignore
              setFieldValue("country", {
                code: "PH",
                country: "Philippines",
              });
            }}
            onRemoveText={() => {
              setFieldValue("streetAddress1", "");
              setFieldValue("placeId", "");
              setFieldValue("city", "");
            }}
          />
          {fieldErrors!.streetAddress1 && <span style={{fontSize: 12, fontFamily: "RockoRegular", color: '#C22D74' }}>Please select your pinned address</span>}
          <S.GoogleImage>
            <img src={powered_by_google} alt="google attribution" />
          </S.GoogleImage>
        </S.RowWithOneCell>
        <S.RowWithOneCell>
          <TextField
            name="streetAddress2"
            label="Address Details (House/Unit No, Street/Building, Barangay)"
            value={values!.streetAddress2}
            autoComplete="address-line2"
            errors={fieldErrors!.streetAddress2}
            borderRadius={5}
            {...basicInputProps()}
          />
        </S.RowWithOneCell>
        <S.RowWithTwoCells>
          <TextField
            disabled={true}
            name="city"
            label="City"
            value={values!.city}
            autoComplete="address-level2"
            errors={fieldErrors!.city}
            borderRadius={5}
            {...basicInputProps()}
          />
          {/* <TextField
            name="postalCode"
            label="ZIP/Postal Code"
            value={values!.postalCode}
            autoComplete="postal-code"
            errors={fieldErrors!.postalCode}
            {...basicInputProps()}
          /> */}
          {includeEmail && (
            <TextField
              name="email"
              label="Email"
              value={values!.email}
              autoComplete="email"
              errors={fieldErrors!.email}
              borderRadius={5}
              {...basicInputProps()}
            />
        )}
        </S.RowWithTwoCells>
        {/* <S.RowWithTwoCells>
          <InputSelect
            defaultValue={defaultValue}
            label="Country"
            name="country"
            options={countriesOptions}
            value={
              values!.country &&
              countriesOptions &&
              countriesOptions!.find(
                option => option.code === values!.country!.code
              )
            }
            onChange={(value: any, name: any) => setFieldValue(name, value)}
            optionLabelKey="country"
            optionValueKey="code"
            errors={fieldErrors!.country}
            autoComplete="country"
          />
          <TextField
            name="countryArea"
            label="State/province"
            value={values!.countryArea}
            autoComplete="address-level1"
            errors={fieldErrors!.countryArea}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells> */}
        
      </S.Wrapper>
    </S.AddressForm>
  );
};
