import React from 'react';
import { useMediaQuery } from 'react-responsive';
// import { AutoComplete } from 'antd';
import './index.scss';
import { OverlayContext } from '@temp/components';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import { mediumScreen } from '@temp/@next/globalStyles/constants';
// import { Autocomplete } from '@material-ui/lab';
// import { TextField } from '@material-ui/core';
// import LocationAutocomplete from '@temp/components/LocationAutocomplete';
import { UserLocationProps } from "src/layout/Context"
import { useLayout } from '@temp/layout/hooks/useLayout';
import AddressAutoComplete from '@temp/components/AddressAutocomplete';
import BrandSearchAutocomplete from '@temp/components/BrandSearchAutocomplete';
import { useHistory } from 'react-router';
import { generateBrandUrl } from '@temp/core/utils';
import { DEFAULT_CURRENT_LOCATION_ADDRESS_TEXT } from "src/constants";

interface NavProps {
	showAddressOverlay: boolean;
	setAddressOverlay: (status: boolean) => void;
	setUserLocation: (data: UserLocationProps) => void;
	showSearchOverlay: boolean;
	setSearchOverlay: (status: boolean) => void;
	removeUserLocation: () => void;
	isLoading?: boolean;
}

const Nav: React.FC<NavProps> = ({
	showSearchOverlay,
	setSearchOverlay,
	showAddressOverlay,
	setAddressOverlay,
	setUserLocation,
	isLoading,
	removeUserLocation,
}) => {
	const isTablet = useMediaQuery({ maxWidth: mediumScreen });
	const { useUserLocation } = useLayout();
	const history = useHistory();
	const userLocation = useUserLocation() || { placeId: "", address1: "", address2: "", latitude: 0, longitude: 0 };

	return (
		<OverlayContext.Consumer>
			{(overlayContext) => (
				<div>
					{showAddressOverlay && <div onClick={() => setAddressOverlay(false)} className="navigation-overlay"></div>}
					{showSearchOverlay && <div onClick={() => setSearchOverlay(false)} className="navigation-searchOverlay"></div>}
					<nav className="py-2 border-bottom navigation">
						<div className="main-container">
							{isTablet ?
								<MobileNav setSearchOverlay={setSearchOverlay} overlayContext={overlayContext} />
								:
								<DesktopNav
									isLoading={isLoading}
									setAddressOverlay={setAddressOverlay}
									overlayContext={overlayContext}
								/>
							}
							{showSearchOverlay &&
								<div className="bg-white navigation__searchAutocomplete">
									<BrandSearchAutocomplete
										setSearchOverlay={setSearchOverlay}
										onChange={(brand) => {
											history.push(generateBrandUrl(brand?.slug));
											setAddressOverlay(false);
										}}

									/>
								</div>
							}
							{showAddressOverlay &&
								<div className="bg-white navigation__autocomplete">
									<AddressAutoComplete
										label="Search Address"
										name="deliveryAddressPin"
										errors={null}
										defaultValue={userLocation?.address1}
										onChange={(value) => {
											setUserLocation({
												address1: value?.name,
												address2: null,
												latitude: null,
												longitude: null,
												placeId: value?.placeId
											});
											setAddressOverlay(false);
										}}
										onLocationClicked={(latitude, longitude) => {
											setUserLocation({
												address1: DEFAULT_CURRENT_LOCATION_ADDRESS_TEXT,
												address2: null,
												latitude,
												longitude,
												placeId: null
											});
											setAddressOverlay(false);
										}}
										onRemoveText={() => {
											removeUserLocation();
										}}
									/>
									{/* <Autocomplete 
								size="small"
								placeholder={'Search Address'}
								options={['bruh', 'bruh']}
								renderInput={(params) => <TextField {...params} label="Enter your Address" variant="outlined"/>}
							/> */}
								</div>}
						</div>
					</nav>
				</div>
			)}
		</OverlayContext.Consumer>
	);
};

export default Nav;
